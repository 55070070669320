<template>
  <div lazy-validation class="session">
    <v-container>
      <v-row justify="center" class="mt-12">
        <v-img :src="appLogo" max-width="280"></v-img>
      </v-row>
      <v-row justify="center" class="mt-8">
        <h2 class="mb-4 primary--text">Ingresa tu nueva contraseña</h2>
      </v-row>
      <v-row justify="center" class="mt-4">
        <base-error :error="error"></base-error>
      </v-row>

      <v-row justify="center">
        <v-col md="4">
          <password-setter> </password-setter>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-4">
        <router-link class="secondary--text" to="/session/login">
          <p>Regresa al inicio de sesión dando click aquí</p>
        </router-link>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PasswordSetter from '@/components/app/password-setter/password-setter'
import AppConfig from '@/constants/app-config'
import { errorMessage } from '@/mixins'
import { SESSION_VERIFY_TOKEN } from '@/store/actions.type'
import { mapActions } from 'vuex'
export default {
  mixins: [errorMessage],
  data() {
    return {
      activeToken: false,
      isLoading: false,
      error: null,
      appLogo: AppConfig.appLogo
    }
  },
  components: {
    PasswordSetter
  },
  methods: {
    ...mapActions({
      verifyToken: SESSION_VERIFY_TOKEN
    })
  },
  created() {
    const token = this.$route.params.token
    this.verifyToken(token)
      .then(reponse => {
      })
      .catch(e => {
        this.$router.push('/password-confirm')
      })
  }
}
</script>
<style>
.sub-text {
  width: 296px;
  font-size: 11px;
}
</style>
